<button (click)="goToPageTop()" *ngIf="isShowTopButton" class="btn btn-top">
    <i class="fas fa-arrow-up"></i>
</button>

<footer class="footer-container">
    <div class="logo-menu-area">
        <h1 class="footer-logo">
            <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/logo/pokerstake_logo.svg'" alt="PokerStake" width="212" height="20" />
        </h1>

        <ul class="link-list">
            <li>
                <strong>PokerStake Info</strong>
                <ul>
                    <li><a routerLink="/about-us" title="About Us more view">About Us</a></li>
                    <li><a href="#contact-us" (click)="$event.preventDefault(); isOpenContactUsModal = true" title="Contact Us more view">Contact Us</a></li>
                </ul>
            </li>
            <li>
                <strong>Legal</strong>
                <ul>
                    <li><a routerLink="/terms-and-conditions" title="Terms of Service more view">Terms and Conditions</a></li>
                    <li><a routerLink="/privacy-policy" title="Privacy Policy more view">Privacy Policy</a></li>
                    <li><a routerLink="/cookie-policy" title="Cookie Policy more view">Cookie Policy</a></li>
                    <li><a routerLink="/dmca-notice" title="DMCA Notice more view">DMCA Notice</a></li>
                    <li><a routerLink="/faq" title="FAQ more view">FAQ</a></li>
                </ul>
            </li>
        </ul>

        <div class="sns-area">
            <ul class="sns-list">
                <li>
                    <a href="https://www.facebook.com/pokerstake/" title="PokerStake Facebook">
                        <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/ico-facebook.webp'" alt="Facebook" width="28" height="28" />
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/pokerstakecom" title="PokerStake X(Twitter)">
                        <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/ico-twitter.webp'" alt="X(Twitter)" width="28" height="28" />
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div class="etc-link-area">
        <ul class="certify-list">
            <li>
                <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/licenses-rg.webp'" width="30" height="30" alt="RG Gaming" />
                <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/licenses-18-plus.webp'" width="40" height="40" alt="18+" />
            </li>
            <li id="GPWA">
                <a href="#GPWA" (click)="openGPWAWindowPopup($event)" title="GPWA more view">
                    <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/licenses-gpwa.webp'" width="68" height="30" alt="GPWA" />
                </a>
            </li>
            <li>
                <a href="https://www.begambleaware.org/" title="be gamble aware">
                    <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/licenses-be-gamble-aware.webp'" width="140" height="40" alt="be gamble aware" />
                </a>
            </li>
            <li>
                <a href="https://www.ncpgambling.org/" title="national council on problem gambling more view">
                    <img class="lazy-img" [customObservable]="footerScrollContainer" [lazyLoad]="environment.imageHost + '/staking/common/footer/licenses-ncpg.webp'" width="99" height="40" alt="national council on problem gambling logo" />
                </a>
            </li>
        </ul>
    </div>

    <address class="copyright">&copy; {{ year }} PokerStake.com</address>
</footer>

<!-- [s] Contact Us Modal -->
<ng-container *ngIf="isOpenContactUsModal">
    <p-dialog
        class="title-small contact-us-modal"
        header="Contact Us"
        [(visible)]="isOpenContactUsModal"
        [closable]="false"
        [modal]="true"
        [focusOnShow]="false"
        [draggable]="false"
        [resizable]="false"
        [blockScroll]="true"
        [style]="{ width: '336px', minHeight: '305px' }"
    >
        <img class="modal-header-logo mail" src="{{ imgHost }}/auth/illust-mail.png" alt="GGPass" />
        <section class="modal-section">
            <b>support&#64;pokerstake.com</b>
            <p class="desc">If you have any question about Pokerstake, please check the <a (click)="isOpenContactUsModal = false" routerLink="/faq" class="link">FAQ</a> first.</p>
            <p class="desc">For more information about Pokerstake.com, visit our <a (click)="isOpenContactUsModal = false" routerLink="/about-us" class="link">About Us</a> page.</p>
            <p class="desc">View our <a (click)="isOpenContactUsModal = false" routerLink="/privacy-policy" class="link">Privacy Policy.</a> page.</p>
        </section>
        <section class="button-area">
            <button pButton type="button" class="p-button-secondary p-button btn" label="Cancel" (click)="isOpenContactUsModal = false"></button>
            <a (click)="isOpenContactUsModal = false" href="mailto:support@pokerstake.com" class="p-button btn">Email Us</a>
        </section>
    </p-dialog>
    <!-- [e] Contact Us Modal -->
</ng-container>
