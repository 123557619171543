import { Routes } from '@angular/router';
import { accessGuard } from '@core/guards/access.guard';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/main/main.routes').then((r) => r.routes),
        data: {
            title: ''
        }
    },
    {
        path: 'staking',
        loadChildren: () => import('./pages/staking/staking.routes').then((r) => r.routes),
        data: {
            title: 'Staking'
        }
    },
    {
        path: 'news',
        loadChildren: () => import('./pages/articles/articles.routes').then((r) => r.routes),
        canActivate: [accessGuard],
        data: {
            title: 'News'
        }
    },
    {
        path: 'articles',
        loadChildren: () => import('./pages/articles/articles.routes').then((r) => r.routes),
        canActivate: [accessGuard],
        data: {
            title: 'News'
        }
    },
    {
        path: 'wallet',
        loadChildren: () => import('./pages/wallet/wallet.routes').then((r) => r.routes),
        canActivate: [accessGuard],
        data: {
            title: 'Payment'
        }
    },
    {
        path: 'my-staking',
        loadChildren: () => import('./pages/my-staking/my-staking.routes').then((r) => r.routes),
        canActivate: [accessGuard],
        data: {
            title: 'My Staking'
        }
    },
    {
        path: 'my-profile',
        loadChildren: () => import('@app/pages/my-profile/my-profile.routes').then((r) => r.routes),
        canActivate: [accessGuard],
        data: {
            title: 'My Profile'
        }
    },
    {
        path: 'manage-follow-player',
        loadComponent: () => import('./pages/manage-follow-player/manage-follow-player.component').then((m) => m.ManageFollowPlayerComponent),
        canActivate: [accessGuard],
        data: {
            title: 'Manage Following Player',
            requireLogin: true
        }
    },
    {
        path: 'promotions',
        loadChildren: () => import('./pages/promotions/promotions.routes').then((r) => r.routes)
    },
    {
        path: 'external',
        loadChildren: () => import('./pages/external/external.routes').then((r) => r.routes)
    },
    {
        path: 'error',
        loadComponent: () => import('@app/pages/errors/error.component').then((m) => m.ErrorComponent)
    },
    {
        path: 'etc',
        loadChildren: () => import('@app/pages/etc/etc.routes').then((r) => r.routes),
        canActivate: [accessGuard]
    },
    {
        path: 'maintenance',
        loadComponent: () => import('@app/pages/maintenance/maintenance.component').then((m) => m.MaintenanceComponent)
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.routes').then((m) => m.routes)
    },
    // {
    //     path: 'articles',
    //     loadChildren: () => import('./pages/articles/articles.module').then((m) => m.ArticlesModule),
    //     canActivate: [AccessGuard],
    //     data: {
    //         title: 'Articles'
    //     }
    // },
    // {
    //     path: 'test-news',
    //     loadChildren: () => import('./pages/articles/articles.routes').then((m) => m.routes),
    //     data: {
    //         title: 'News'
    //     }
    // },
    {
        path: '**',
        redirectTo: ''
    }
];
