<ul class="navbar-submenu" [class.open]="isOpen">
    <li *ngIf="isInNavBar" class="menu-back">
        <a (click)="closeSubMenu.emit()"><i class="fas fa-angle-left"></i>Back</a>
    </li>
    <li *ngIf="!isInNavBar" class="title">Content</li>
    <li class="sub-menu-item"><a routerLink="/my-profile" routerLinkActive="active" (click)="onCloseSideMenu()">My Profile</a></li>
    <li class="sub-menu-item"><a [routerLink]="['/my-staking', isSeller ? 'sold' : 'bought']" routerLinkActive="active" (click)="onCloseSideMenu()">My Staking</a></li>
    <li class="sub-menu-item"><a routerLink="/manage-follow-player" routerLinkActive="active" (click)="onCloseSideMenu()">Manage Following Player</a></li>
    <li *ngIf="!isInNavBar" class="top-line"><a (click)="logout()">Sign Out</a></li>
</ul>
