import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { MaintenanceService } from '@core/services/maintenance.service';
import { PlayerStateService } from '@core/states/player-state.service';
import { ClientInfoService } from '@core/services/client-info.service';

export const accessGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const maintenanceService = inject(MaintenanceService);

    if (maintenanceService.isP5Maintenance() && !maintenanceService.config.getValue()?.isInDevIp) {
        return router.navigate(['maintenance']);
    }

    const isPassGuard = state.url.includes('logout') || state.url.includes('verify') || state.url.includes('auth/password-reset/success');
    if (isPassGuard) {
        return true;
    }

    const playerStateService = inject(PlayerStateService);

    // 로그인 체크
    const isLogin = playerStateService.isSignedIn.getValue();

    if (isLogin) {
        const clientInfoService = inject(ClientInfoService);
        // login, register 로그인 된 상태에서는 메인으로
        if (route.data.requireLogout) {
            router.navigate(['']);
            return false;
        }

        const playerInfo = playerStateService.info.getValue();

        // 새로고침해서 login 된 상태(쿠키)인데 playerInfo가 없을때 or 토큰없을때는 다시 발급
        if (!playerInfo || !playerStateService.accessToken.getValue()) {
            const success = await playerStateService.refreshToken(clientInfoService.clientInfo);
            // 실패하면 로그아웃 되니 메인으로;
            if (!success) {
                router.navigate(['']);
                return false;
            }
        }

        // GGPass Guard
        // GGpass 동의가 필요한 경우
        const ggPassInfo = playerStateService.ggPassInfo.getValue();
        if (!ggPassInfo?.isAgreement) {
            if (!state.url.includes('intermediate/sso-terms')) {
                router.navigate(['/auth/intermediate/sso-terms']);
                return false;
            }
            return true;
        }

        // GGpass Nickname 셀렉트
        if (ggPassInfo?.additionalNicknames?.length) {
            if (!state.url.includes('intermediate/select-nickname')) {
                router.navigate(['/auth/intermediate/select-nickname']);
                return false;
            }
            return true;
        }

        // GGpass Nickname, Avatar 설정이 필요한 경우
        if (!ggPassInfo?.initializedAt || !ggPassInfo?.isNicknameEnabled) {
            if (!state.url.includes('intermediate/profile-setup')) {
                router.navigate(['/auth/intermediate/profile-setup']);
                return false;
            }
            return true;
        }

        // GGPass 가드 모두 통과하고 직접 url접근때는 메인으로
        if (route.data.isGGPassFlow) {
            router.navigate(['']);
            return false;
        }

        return true;
    }

    // wallet, my-staking, register-before-veri.., delete-account 등 로그인 된 상태에서만 접근가능
    if (!isLogin && route.data.requireLogin) {
        return router.navigate([`/auth/login`], { queryParams: { redirectUrl: state.url } });
    }

    return true;
};
