import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, effect, Inject, NO_ERRORS_SCHEMA, OnDestroy, OnInit, viewChild, ViewChild, ViewContainerRef } from '@angular/core';
import { Event, NavigationEnd, Router, RouterLink } from '@angular/router';
import { DOCUMENT, NgIf } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { combineLatest, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { DTO, VM } from '@models';
import dayjs from 'dayjs';
import { environment } from '@environments/environment';
import { NavbarComponent } from '@shared/components/layout/navbar/navbar.component';
import { MaintenanceNoticeComponent } from '@shared/components/layout/maintenance-notice/maintenance-notice.component';
import { PlayerStateService } from '@core/states/player-state.service';
import { PlayerService } from '@core/services/player.service';
import { TimerService } from '@core/services/timer.service';
import { ServerTimeService } from '@core/services/server-time.service';
import { StakingFilterToggleStateService } from '@shared/states/staking-filter-toggle-state.service';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { MoneyPipe } from '@shared/pipes/money.pipe';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [NgIf, NavbarComponent, MaintenanceNoticeComponent, OverlayPanelModule, RouterLink, MoneyPipe],
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('visibleCloseMenuButton', [
            state('true', style({ opacity: 1, visibility: 'visible' })),
            state('void', style({ opacity: 0, visibility: 'hidden' })),
            transition(':enter', [animate('200ms ease-in-out')]),
            transition(':leave', [animate('0ms')])
        ])
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class HeaderComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject<void>();
    private isPendingCheckBalance = false;

    public isWalletPage = false;
    public environment = environment;
    public isSignedIn = false;
    public playerInfo: DTO.FrontOffice.Member.MemberInfo;
    public balance: VM.UpdateUserBalance;
    public isSeller: boolean;
    public isShowSideMenu = false;
    public isInitialize = false;
    public isSelectedStakingFilter = false;

    private myAvatar = viewChild('myAvatar', { read: ViewContainerRef });
    private accountMenu = viewChild('accountMenu', { read: ViewContainerRef });
    @ViewChild('profileToggleMenu') private profileToggleMenu: OverlayPanel;

    public constructor(
        @Inject(DOCUMENT) public document: Document,
        private router: Router,
        private cdRef: ChangeDetectorRef,
        private timer: TimerService,
        private serverTime: ServerTimeService,
        private playerService: PlayerService,
        private playerState: PlayerStateService,
        private stakingFilterToggleStateService: StakingFilterToggleStateService
    ) {
        this.balance = {
            value: 0,
            isUpdate: false
        };

        effect(async () => {
            const ref = this.myAvatar();
            if (!ref || ref.length) {
                return;
            }
            ref.clear();
            const MyAvatarComponent = (await import('@shared/components/member/profile/my-avatar/my-avatar.component')).MyAvatarComponent;
            const myAvatarComponentRef = ref.createComponent(MyAvatarComponent);
            myAvatarComponentRef?.setInput('size', 'small');
        });
        effect(async () => {
            const ref = this.accountMenu();
            if (!ref || ref.length) {
                return;
            }
            const AccountMenuComponent = (await import('@shared/components/layout/account-menu/account-menu.component')).AccountMenuComponent;
            ref.clear();
            const accountMenuComponentRef = ref.createComponent(AccountMenuComponent);
            accountMenuComponentRef.instance.closeAccountMenu.subscribe(() => {
                this.profileToggleMenu.toggle(false);
            });
        });
    }

    public ngOnInit() {
        this.initData();

        this.router.events
            .pipe(
                filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
                startWith(this.router),
                takeUntil(this.unsubscribe)
            )
            .subscribe((event: NavigationEnd) => {
                if (event instanceof NavigationEnd) {
                    this.isWalletPage = event.url.startsWith('/wallet');
                    this.cdRef.markForCheck();
                }
            });

        this.stakingFilterToggleStateService.isSelectedStakingFilter.pipe(takeUntil(this.unsubscribe)).subscribe((isSelectedStakingFilter) => {
            this.isSelectedStakingFilter = isSelectedStakingFilter;
            this.cdRef.detectChanges();
        });

        this.isInitialize = true;
        this.cdRef.markForCheck();
    }

    private initData() {
        combineLatest([this.playerState.info, this.playerState.balance])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                [this.playerInfo, this.balance] = data;
                this.cdRef.markForCheck();
            });

        this.playerState.isSignedIn.pipe(takeUntil(this.unsubscribe)).subscribe(async (isSignedIn) => {
            this.isSignedIn = isSignedIn;
            this.cdRef.markForCheck();
        });
        this.playerState.accessToken.pipe(takeUntil(this.unsubscribe)).subscribe(async (accessToken) => {
            if (!!accessToken) {
                await this.setPlayerBalance();
            }
            this.cdRef.markForCheck();
        });
        this.playerState.info.pipe(takeUntil(this.unsubscribe)).subscribe(async (playerInfo) => {
            this.isSeller = playerInfo && playerInfo.memberClass === DTO.Enums.Common.MemberClass.Seller;
            this.cdRef.markForCheck();
        });

        this.timer.get().pipe(takeUntil(this.unsubscribe)).subscribe(this.tick);
    }

    private tick = async () => {
        if (!this.serverTime.current) {
            return;
        }

        this.serverTime.current = dayjs.utc(this.serverTime.current).add(1, 'second');
        // this.serverTime.current.add(1, 's').unix();

        // if (0 === this.serverTime.timestamp % 10) {
        //     await this.setPlayerBalance();
        // }
    };

    private async setPlayerBalance() {
        if (this.isSignedIn && !this.isPendingCheckBalance) {
            try {
                this.isPendingCheckBalance = true;
                const res = await this.playerService.getPlayerBalance();

                this.balance.value = res.balance;
                this.playerState.balance.next({ value: res.balance, isUpdate: false });
            } catch (e) {
                console.error('fail to check balance', e);
            } finally {
                this.isPendingCheckBalance = false;
                this.cdRef.markForCheck();
            }
        }
    }

    public toggleSideMenu(isToggle) {
        this.isShowSideMenu = isToggle;
        this.cdRef.detectChanges();
    }

    public onToggleFilterPanel() {
        this.stakingFilterToggleStateService.onToggleFilterPanel();
    }

    /**
     * 모바일 필터 아이콘노출
     */
    public get isVisibleToggleFilterButton() {
        return this.router.url.includes('poker-staking') || this.router.url.includes('search');
    }

    public updateBalanceAnimationEnd() {
        this.balance.isUpdate = false;
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.myAvatar()?.clear();
        this.accountMenu()?.clear();
    }
}
