import { Inject, Injectable } from '@angular/core';
import { ClientInfo, ClientOsType, DeviceType } from '@models/dto/front-office/auth';
import { DOCUMENT } from '@angular/common';
import UAParser from 'ua-parser-js';
import { v1 as uuidv1 } from 'uuid';

export const guidStorageKey = 'additionalAuthorizationStore';
export const clientInfoStorageKey = 'clientStore';

@Injectable({
    providedIn: 'root'
})
export class ClientInfoService {
    public readonly clientInfo: ClientInfo;

    public constructor(@Inject(DOCUMENT) private document: Document) {
        const clientInfoStorage = document.defaultView.localStorage.getItem(clientInfoStorageKey);
        if (!clientInfoStorage) {
            // clientInfo 안에 guid 를 저장해 더 이상 필요 없음
            let guid = document.defaultView.localStorage.getItem(guidStorageKey);

            if (!guid) {
                guid = uuidv1();
            } else {
                // guid 저장 제거
                document.defaultView.localStorage.removeItem(guidStorageKey);
            }

            const parser = new UAParser();

            const result = parser.getResult();

            this.clientInfo = {
                clientDeviceType: 'mobile' === result.device.type ? DeviceType.MOBILE : DeviceType.PC,
                clientDeviceIdNew: guid,
                clientOsType: result.os.name.toUpperCase() as ClientOsType,
                clientOsVersion: `${result.os.name} ${result.os.version} ${result.cpu.architecture || ''}`,
                clientDeviceName: result.device.vendor || result.browser.name,
                clientDeviceModel: result.device.model || 'browser'
            };

            document.defaultView.localStorage.setItem(clientInfoStorageKey, JSON.stringify(this.clientInfo));
        } else {
            this.clientInfo = JSON.parse(clientInfoStorage);
        }

        // Enum 보정 로직
        // AIX, Amiga OS, Android, Arch, Bada, BeOS, BlackBerry, CentOS, Chromium OS, Contiki, Fedora, Firefox OS, FreeBSD,
        // Debian, DragonFly, Gentoo, GNU, Haiku, Hurd, iOS, Joli, Linpus, Linux, Mac OS, Mageia, Mandriva, MeeGo, Minix,
        // Mint, Morph OS, NetBSD, Nintendo, OpenBSD, OpenVMS, OS/ 2, Palm, PCLinuxOS, Plan9, Playstation, QNX, RedHat,
        // RIM Tablet OS, RISC OS, Sailfish, Series40, Slackware, Solaris, SUSE, Symbian, Tizen, Ubuntu, UNIX, VectorLinux,
        // WebOS, Windows [Phone/ Mobile], Zenwalk

        const chromeOs = document.defaultView.navigator.userAgent.indexOf('CrOS') > -1 && this.clientInfo.clientOsType !== ClientOsType.CHROME_OS;

        if (!ClientOsType[this.clientInfo.clientOsType] || chromeOs) {
            if ('CHROMIUM OS' === (this.clientInfo.clientOsType as string) || chromeOs) {
                this.clientInfo.clientOsType = ClientOsType.CHROME_OS;
            } else if ('MAC OS' === (this.clientInfo.clientOsType as string)) {
                this.clientInfo.clientOsType = ClientOsType.MAC;
            } else if (
                this.clientInfo.clientOsType !== ClientOsType.ANDROID &&
                this.clientInfo.clientOsType !== ClientOsType.IOS &&
                this.clientInfo.clientOsType !== ClientOsType.MAC &&
                this.clientInfo.clientOsType !== ClientOsType.WINDOWS &&
                this.clientInfo.clientOsType !== ClientOsType.UNKNOWN
            ) {
                this.clientInfo.clientOsType = ClientOsType.UNKNOWN;
            }
            document.defaultView.localStorage.setItem(clientInfoStorageKey, JSON.stringify(this.clientInfo));
        }
    }
}
