import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, provideRouter, RouteReuseStrategy, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { httpHostInterceptor } from '@core/interceptors/http-host.interceptor';
import { environment } from '@environments/environment';
import { routes } from './app.routes';

class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) {
        return future.data.shouldReuse ?? future.routeConfig === curr.routeConfig;
    }
}

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
        provideServiceWorker('TEST' === environment.env ? 'mockServiceWorker.js' : 'serviceWorker.js', {
            enabled: true,
            registrationStrategy: 'registerImmediately'
            // enabled: environment.production
        }),
        provideHttpClient(withInterceptors([httpHostInterceptor])),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAnimations(),
        provideRouter(
            routes,
            withRouterConfig({
                onSameUrlNavigation: 'reload'
            }),
            withInMemoryScrolling({
                scrollPositionRestoration: 'disabled'
            })
        ),
        DialogService,
        MessageService,
        ConfirmationService
    ]
};
