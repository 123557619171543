import { bootstrapApplication } from '@angular/platform-browser';
import { enableProdMode } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';

import { AppComponent } from '@app/app.component';
import { environment } from '@environments/environment';
import { appConfig } from '@app/app.config';
import { BehaviorSubject } from 'rxjs';

setTimeout(() => {
    if (environment.production) {
        enableProdMode();
    }

    bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));

    import('swiper/element/bundle').then((module) => {
        module.register();
        GlobalService.swiperLoaded.next(true);
    });

    try {
        dayjs.extend(utc);
        dayjs.extend(isoWeek);
        dayjs.extend(isBetween);
        dayjs.extend(duration);
    } catch (err) {
        console.error(err);
    }
}, 1);

declare let window: Window;

export class GlobalService {
    static swiperLoaded = new BehaviorSubject(false);
    static articleItemList = new BehaviorSubject(this.getArticlesData());

    static getArticles = () => {
        this.setArticlesData(null);
        fetch(`${environment.ggPokerNewsHost}/api/wp/v2/posts?psk_only=1&page=1&per_page=5&_fields=id,title,excerpt,featured_media_raw,slug`)
            .then((response) => response.json())
            .then((data) => {
                if (data?.length) {
                    this.setArticlesData(data);
                } else {
                    this.setArticlesData('fail');
                }
            })
            .catch(() => {
                this.setArticlesData('fail');
            });
    };

    static setArticlesData(value) {
        this.articleItemList.next(value); // this will make sure to tell every subscriber about the change.
        window.localStorage.setItem('articles', JSON.stringify(value));
    }

    static getArticlesData() {
        try {
            return JSON.parse(window.localStorage.getItem('articles'));
        } catch {
            return null;
        }
    }
}

if ('/' === window.location.pathname) {
    GlobalService.getArticles();
}

// if ('TEST' === environment.env) {
//     mocking.start({ onUnhandledRequest: 'bypass' });
// }
