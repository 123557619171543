import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, HostListener, Inject, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { DOCUMENT, NgIf } from '@angular/common';
import { environment } from '@environments/environment';
import { fromEvent, merge, Observable } from 'rxjs';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks } from 'ng-lazyload-image';
import { RouterLink } from '@angular/router';
import { ButtonDirective } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { Ripple } from 'primeng/ripple';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [LazyLoadImageModule, RouterLink, NgIf, ButtonDirective, DialogModule, Ripple],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: [[{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }]]
})
export class FooterComponent implements OnInit {
    public imgHost = environment.imageHost;
    public isShowTopButton: boolean = false;
    public isOpenContactUsModal: boolean = false;
    public footerScrollContainer: Observable<any>;
    public environment = environment;
    public year: number = new Date().getFullYear();

    public constructor(@Inject(DOCUMENT) private document: Document) {}

    @HostListener('window:scroll', ['$event'])
    public windowScroll(event) {
        this.isShowTopButton = event.currentTarget.scrollY > 100;
    }

    public ngOnInit() {
        this.setScrollContainerForLazyLoadImage();
    }

    public goToPageTop() {
        this.document.defaultView.scrollTo({ top: 0, behavior: 'smooth' });
    }

    public openGPWAWindowPopup(e) {
        e.preventDefault();
        this.document.defaultView.open('//certify.gpwa.org/verify/pocketfives.com/', 'gpwaPopup', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=640,height=680');
    }

    private setScrollContainerForLazyLoadImage() {
        this.footerScrollContainer = merge(fromEvent(this.document.defaultView, 'scroll'), fromEvent(this.document.defaultView, 'mouseover'));
    }
}
