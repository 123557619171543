<ng-container *ngIf="!isMaintenancePage && !externalPageLayout; else noLayoutContainer">
    <app-header class="header"></app-header>
    <article class="contents">
        <router-outlet></router-outlet>
    </article>
    <app-footer *ngIf="isFooterVisible" class="footer"></app-footer>
</ng-container>

<ng-template #noLayoutContainer>
    <article class="contents">
        <router-outlet></router-outlet>
    </article>
</ng-template>

<p-toast [style]="{ opacity: 1 }"></p-toast>
<p-confirmDialog></p-confirmDialog>
