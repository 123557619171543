import { inject } from '@angular/core';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { Router } from '@angular/router';

import { catchError, from, lastValueFrom, Subject, switchMap, takeUntil, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { DTO } from '@models';
import { TimerService } from '@core/services/timer.service';
import { PlayerStateService } from '@core/states/player-state.service';
import { ClientInfoService } from '@core/services/client-info.service';

export const httpHostInterceptor: HttpInterceptorFn = (request, next) => {
    const subscriber = new Subject<void>();

    if (request.url.includes(environment.stream) || request.url.includes('assets/js')) {
        return next(
            request.clone({
                url: request.url,
                withCredentials: true
            })
        ).pipe(
            takeUntil(subscriber),
            catchError((error: HttpErrorResponse) => throwError(() => error))
        );
    }

    let endPoint = environment.backendHost;
    if (request.url.includes('/Payment/') || request.url.includes('/PaymentAccount/')) {
        endPoint = environment.paymentHost;
    } else if (request.url.includes('//')) {
        endPoint = '';
    }

    // p5 인증 필요 없는 api는 false
    let isWithCredentials = true;
    if (request.url.includes(environment.r2Host)) {
        isWithCredentials = false;
    }

    const playerStateService = inject(PlayerStateService);
    const clientInfoService = inject(ClientInfoService);
    const router = inject(Router);
    const timer = inject(TimerService);

    return next(
        request.clone({
            url: `${endPoint}${request.url}`,
            withCredentials: isWithCredentials,
            headers: playerStateService.accessToken.getValue() && !request.url.includes(environment.r2Host) ? request.headers.set('Authorization', `Bearer ${playerStateService.accessToken.getValue()}`) : null
        })
    ).pipe(
        catchError((error: HttpErrorResponse) => {
            let message = `Error Status: ${error.status}\nMessage: `;
            const signOutErrorCodes = [DTO.Enums.Common.ErrorCodes.SESSION_EXPIRED, DTO.Enums.Common.ErrorCodes.INVALID_SESSION, DTO.Enums.Common.ErrorCodes.INVALID_PLAYER, DTO.Enums.Common.ErrorCodes.GGPASS_REFRESH_TOKEN_EMPTY];

            if (signOutErrorCodes.includes(error?.error?.errorCode)) {
                message += error.error.description;

                timer.stop();
                router.navigateByUrl('/auth/logout');
            } else {
                message += error.message;
            }

            if (error instanceof HttpErrorResponse && 401 === error.status) {
                return from(playerStateService.refreshToken(clientInfoService.clientInfo)).pipe(
                    switchMap((success) => {
                        if (success) {
                            return lastValueFrom(
                                next(
                                    request.clone({
                                        url: `${endPoint}${request.url}`,
                                        withCredentials: true,
                                        headers: playerStateService.accessToken.getValue() ? request.headers.set('Authorization', `Bearer ${playerStateService.accessToken.getValue()}`) : null
                                    })
                                )
                            );
                        }
                    })
                );
            }
            console.error('Error message', message);
            return throwError(error);
        })
    );
};
