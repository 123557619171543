import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr/dist/esm/IHttpConnectionOptions';
import { environment } from '@environments/environment';
import { UpdatePaymentService } from '@app/pages/wallet/@shared/states/update-payment.service';
import { skip } from 'rxjs/operators';
import { MaintenanceService } from '@core/services/maintenance.service';
import { PlayerService } from '@core/services/player.service';
import { PlayerStateService } from '../states/player-state.service';
import { UpdateStakingService } from '../states/update-staking.service';

@Injectable({ providedIn: 'root' })
export class SignalRService {
    private hubConnection: signalR.HubConnection;
    public environment = environment;

    public constructor(
        private playerStateService: PlayerStateService,
        private updateStakingService: UpdateStakingService,
        private updatePaymentService: UpdatePaymentService,
        private maintenanceService: MaintenanceService,
        private playerService: PlayerService
    ) {
        this.playerStateService.accessToken.pipe(skip(1)).subscribe(async (token) => {
            await this.initSignalR(true);
        });
    }

    public async initSignalR(isSignedIn: boolean = false) {
        const accessToken = isSignedIn ? await this.playerStateService.accessToken.getValue() : null;

        if ('Connected' === this.hubConnection?.state) {
            this.hubConnection.stop();
        }

        if (!!accessToken) {
            this.startConnection(accessToken);
            this.onPlayerBalanceListener();
        } else {
            this.startConnection();
        }

        this.onMaintenanceListener();
    }

    public startConnection = (token: string = null) => {
        let option = {} as IHttpConnectionOptions;

        if (token) {
            option = { accessTokenFactory: () => `${token}` };
        }
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.stream}/stakeHub`, option)
            // .configureLogging(!environment.production ? LogLevel.Debug : LogLevel.None)
            .withAutomaticReconnect()
            .build();

        this.hubConnection.serverTimeoutInMilliseconds = 200000; // hubOptions.KeepAliveInterval = TimeSpan.FromMinutes(3); 보다 커야 함.
        this.hubConnection.onreconnecting((err) => {
            if (!environment.production) {
                console.error(`reconnecting due to ${err.toString()}`);
            }
        });

        this.hubConnection
            .start()
            .then(() => console.log('Connection started'))
            .catch((err) => console.error(`Error while starting connection: ${err}`));
    };

    public onPlayerBalanceListener = () => {
        this.hubConnection.on('UpdateBalance', (data) => {
            if (data) {
                this.playerStateService.balance.next({ value: data.balance, isUpdate: true });
            }
        });
    };

    public onUpdateStakingListener = () => {
        this.hubConnection?.on('UpdateStakes', (data) => {
            if (0 < data?.length) {
                this.updateStakingService.updateStaking.next(data);
            }
        });
    };

    public onUpdatePaymentListener = () => {
        this.hubConnection?.on('UpdatePaymentStatus', (data) => {
            if (data) {
                this.updatePaymentService.updatePaymentMessage.next(data);
            }
        });
    };

    // 메인터넌스 리스너
    public onMaintenanceListener = () => {
        this.hubConnection.on('UpdateMaintenance', (data) => {
            const mergeInfo = {
                p5: data.p5,
                gg: data.gg,
                wsopca: data.wsopca,
                isInDevIp: this.maintenanceService.config.getValue().isInDevIp
            };

            this.maintenanceService.config.next(mergeInfo);
        });
    };
}
