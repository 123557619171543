import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { DTO } from '@models';
import { takeUntil } from 'rxjs/operators';
import { PlayerStateService } from '@core/states/player-state.service';
import { Subject } from 'rxjs';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'app-account-menu',
    standalone: true,
    imports: [NgIf, RouterLink, RouterLinkActive],
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit, OnDestroy {
    @Input() public isInNavBar: boolean;
    @Input() public isOpen: boolean;
    @Output() public closeAccountMenu = new EventEmitter<boolean>();
    @Output() public closeSubMenu = new EventEmitter();

    public playerInfo: DTO.FrontOffice.Member.MemberInfo;
    public isSeller: boolean;
    private unsubscribe = new Subject<void>();

    public constructor(private router: Router, private playerStateService: PlayerStateService) {}

    public ngOnInit() {
        this.playerInfo = this.playerStateService.info.getValue();

        this.playerStateService.info.pipe(takeUntil(this.unsubscribe)).subscribe((playerInfo) => {
            this.playerInfo = playerInfo;
            this.isSeller = playerInfo?.memberClass === DTO.Enums.Common.MemberClass.Seller;
        });
    }

    public async logout() {
        await this.playerStateService.signOut();
        this.router.navigateByUrl('/');
    }

    public onCloseSideMenu() {
        this.closeAccountMenu.emit();
    }

    public ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
