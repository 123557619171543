export enum LoginVerificationStatus {
    Plain,
    DuplicatedP5,
    DuplicatedSend,
    DuplicatedAll,
    P5Unverified,
    AfterChangeEmail,
    ChangingEmail,
    Required2FaEmail,
    Required2FaOtp
}

export enum MemberClass {
    Normal = 0,
    Seller = 1
}

export enum PaymentServiceProvider {
    Unknown = 0,
    PayPal = 1,
    Stripe = 2,
    CreditCard = 3,
    CoinPayments = 4,
    Braintree = 5,
    AuthorizeNet = 6,
    Nuvei = 7
}

export enum PSPName {
    Unknown = 0,
    PayPal = 10,
    Stripe = 11,
    CreditCard = 12,
    CoinPayments = 13,
    Braintree = 14,
    AuthorizeNet = 15,
    Nuvei = 16,
    PIX = 17,
    NPay = 18
}

export enum StakeProgress {
    Processing = 0,
    Success = 1,
    Canceled = 2
}

export enum StakeTransactionProgress {
    Purchase = 0,
    Success = 1,
    Failed = 2,
    Refund = 3,
    Pending = 4,
    Canceled = 5
}

export enum TransactionType {
    Deposit = 0,
    Withdrawal = 1,
    WithdrawalCanceled = 2,
    WithdrawalFailed = 3,
    WithdrawalDecline = 4,
    StakePurchase = 5,
    StakeResult = 6,
    StakeRefund = 7,
    AdminCredit = 8,
    AdminDebit = 9,
    PrizeSettlement = 10,
    StakeSale = 11,
    PromotionCredit = 12,
    StakeBounty = 13,
    GGAdminCredit = 14,
    GGAdminDebit = 15,
    PaymentRefund = 16,
    PaymentRefundFailed = 17,
    PurchaseCanceled = 18,
    StakeBountyJackpot = 21
}

export enum PaymentType {
    Deposit = 0,
    Withdrawal = 1
}

export enum PaymentProcessStatus {
    Created = 0,
    Pending = 1,
    Reviewing = 2,
    Confirmed = 3,
    Processing = 4,
    Completed = 5,
    Failed = 6,
    Declined = 7,
    Canceled = 8
}

export enum SystemTransactionConfigStatus {
    Off = 0,
    On = 1,
    Maintenance = 2
}

export enum BalanceTransferType {
    Credit = 0,
    Debit = 1
}

export enum CoinPaymentsCurrencyStatus {
    Online = 0,
    Maintenance = 1
}

export enum CoinPaymentsCapabilityType {
    Payments = 0,
    Wallet = 1,
    Transfers = 2,
    Convert = 3,
    DestinationTag = 4
}

export enum CoinPaymentsCommandType {
    Rates,
    GetCallbackAddress,
    CreateWithdrawal,
    CreateTransaction,
    GetTxInfo,
    Balances
}

export enum PaymentCoinPaymentsResultCode {
    Unknown,
    Failed,
    Pending,
    Success,
    Canceled
}

export enum TournamentCurrency {
    USD = 0,
    EUR = 1,
    GBP = 2,
    RUB = 3,
    JPY = 4,
    KRW = 5,
    CNY = 6,
    HKD = 7,
    TWD = 8,
    KHR = 9,
    VND = 10,
    PHP = 11,
    INR = 12,
    THB = 13,
    IDR = 14,
    MYR = 15,
    SGD = 16,
    SEK = 17,
    NOK = 18,
    CHF = 19,
    CAD = 20,
    AMD = 21,
    DKK = 22,
    AUD = 25,
    BRL = 26
}

export enum S3ObjectType {
    TEMP = 0,
    VENUE = 1,
    PSP = 2,
    BRAND = 3,
    SERIES = 4
}

export enum SlackApiCommandType {
    PostMessage
}

export enum SlackChannel {
    P5Notification
}

export enum PromotionStatus {
    Pending = 0,
    Completed = 1,
    NotExist = 2,
    Duplicated = 3,
    Invalid = 4
}

export enum OriginType {
    P5 = 0,
    GG = 1,
    WSOPCA = 2
}

export enum GGTournamentStatus {
    Created = 0,
    Announced = 1,
    Registering = 2,
    Running = 3,
    Canceled = 4,
    Completed = 5,
    Removed = 6,
    Obsolete1 = 7,
    Obsolete2 = 8,
    Stopped = 9,
    Refunding = 10,
    Invalid = 11,
    Waiting = 12
}

export enum StakeListType {
    FeaturedPlayer,
    StakeAgain,
    FollowingPlayer,
    Series,
    Tournament
}

export enum SnsService {
    FaceBook,
    Twitter
}

export enum StakeMailType {
    StakeCreated = 0,
    StakeSoldOut = 1,
    StakeCompleted = 2,
    StakeCanceled = 3
}

export enum MailSendProgress {
    Processing = 0,
    Success = 1,
    Failed = 2
}

export enum AdminListColumnSort {
    AdminId,
    CreatedAt,
    AdminEmail,
    AdminName,
    ParentAdminName,
    IsDeleted
}

export enum AdminLoginHistoryListColumnSort {
    AdminLoginHistoryId,
    AdminId,
    CreatedAt,
    IsFailed,
    IPAddress,
    AdminName,
    AdminEmail
}

export enum MemberListColumnSort {
    PlayerId,
    CreatedAt,
    PlayerName,
    PlayerEmail,
    MemberClass,
    IsDeleted,
    IsDemo,
    Balance,
    P5Id
}

export enum MemberLoginHistoryListColumnSort {
    PlayerLoginHistoryId,
    PlayerId,
    PlayerName,
    PlayerEmail,
    IsFailed,
    IPAddress,
    Country,
    CreatedAt,
    MemberClass
}

export enum VenueListColumnSort {
    VenueId,
    CreatedAt,
    VenueName,
    TimeZone,
    VenueAddress,
    TournamentCount,
    StakeCount
}

export enum TournamentListColumnSort {
    TournamentId,
    CreatedAt,
    VenueName,
    StartTime,
    Title,
    BuyIn,
    Guarantee
}

export enum StakeListColumnSort {
    StakeId,
    StartTime,
    Progress,
    CreatedAt,
    StakersCount
}

export enum PaymentHistoryColumnSort {
    TXID,
    PaymentType,
    PlayerName,
    Amount,
    UpdateAt
}

export enum AdminAuditListColumnSort {
    AuditId,
    CreatedAt,
    AdminId,
    AdminName,
    AdminEmail,
    Contents
}

export enum MemberAuditListColumnSort {
    AuditId,
    CreatedAt,
    PlayerId,
    PlayerName,
    PlayerEmail,
    Contents
}

export enum PaymentReportColumnSort {
    Date,
    DepositAmount,
    Deposits,
    Depositers,
    WithdrawalAmount,
    Withdrawals,
    Withdrawalers,
    NetDeposit,
    PSPFee,
    PSPName,
    PlayerId,
    PlayerName,
    PlayerEmail
}

export enum StakingReportColumnSort {
    Date,
    TotalStakings,
    OpenStakings,
    ClosedStakings,
    TotalSold,
    TotalPurchases,
    TotalStakers,
    Sales,
    Prize,
    Profits,
    Roi,
    VenueName,
    TournamentId,
    TournamentName,
    SellerId,
    SellerName,
    SellerEmail
}

export enum MemberStakingReportColumnSort {
    PlayerId,
    PlayerName,
    PlayerEmail,
    PurchaseCount,
    PurchaseAmount,
    PrizeCount,
    PrizeAmount,
    Roi,
    DepositCount,
    DepositAmount,
    WithdrawalCount,
    WithdrawalAmount,
    NetDeposit,
    IsDeleted
}

export enum PromotionTransactionColumnSort {
    PromotionTransactionId,
    RequestEmail,
    AdminId,
    AdminName,
    PlayerId,
    PlayerName,
    PlayerEmail,
    Status,
    CreatedAt,
    UpdatedAt
}

export enum SellerListColumnSort {
    SellerId,
    OriginType,
    SellerKey,
    SellerName,
    SellerEmail,
    IsActive,
    IsDeleted,
    IsDemo,
    CreatedAt,
    UpdatedAt
}

export enum BrandListColumnSort {
    BrandId,
    BrandName,
    BrandLinkUrl,
    TournamentCount,
    StakeCount
}

export enum SeriesListColumnSort {
    SeriesId,
    SeriesName,
    VenueName,
    BrandName,
    SeriesBeginAt,
    TournamentCount,
    StakeCount
}

export enum SearchStakeListColumnSort {
    StartTime,
    CreatedAt,
    Markup,
    BuyIn,
    Available,
    UniqueBuyerCount
}

export enum StakeViewMoreListColumnSort {
    StartTime,
    CreatedAt,
    MarkupLow,
    MarkupHigh,
    BuyInLow,
    BuyInHigh,
    SellerName,
    AvailableLow,
    AvailableHigh
}

export enum PaymentRefundTransactionListColumnSort {
    RefundTxId,
    PaymentTxId,
    ProcessStatus,
    CreatedAt
}

export enum ErrorCodes {
    SUCCESS = 'SUCCESS',
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    ACCESS_DENIED = 'ACCESS_DENIED',
    SESSION_EXPIRED = 'SESSION_EXPIRED',
    INVALID_REQUEST = 'INVALID_REQUEST',
    INVALID_RESPONSE = 'INVALID_RESPONSE',
    INVALID_P5_SESSION = 'INVALID_P5_SESSION',
    INVALID_SESSION = 'INVALID_SESSION',
    INVALID_PLAYER = 'INVALID_PLAYER',
    INVALID_ADMIN = 'INVALID_ADMIN',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    INVALID_NAME_RULE = 'INVALID_NAME_RULE',
    INVALID_EMAIL_RULE = 'INVALID_EMAIL_RULE',
    INVALID_PASSWORD_RULE = 'INVALID_PASSWORD_RULE',
    INVALID_PERMISSION = 'INVALID_PERMISSION',
    NAME_ALREADY_EXISTS = 'NAME_ALREADY_EXISTS',
    EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
    DATA_ALREADY_EXISTS = 'DATA_ALREADY_EXISTS',
    DATA_NOT_FOUND = 'DATA_NOT_FOUND',
    DATA_NOT_EMPTY = 'DATA_NOT_EMPTY',
    NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
    FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED',
    FILE_DOWNLOAD_FAILED = 'FILE_DOWNLOAD_FAILED',
    PAYMENT_DEPOSIT_CANCELED = 'PAYMENT_DEPOSIT_CANCELED',
    PAYMENT_DEPOSIT_FAILED = 'PAYMENT_DEPOSIT_FAILED',
    PAYMENT_WITHDRAWAL_FAILED = 'PAYMENT_WITHDRAWAL_FAILED',
    PAYMENT_REQUEST_FAILED = 'PAYMENT_REQUEST_FAILED',
    PAYMENT_PENDING = 'PAYMENT_PENDING',
    AMOUNT_MIN_ERROR = 'AMOUNT_MIN_ERROR',
    AMOUNT_MAX_ERROR = 'AMOUNT_MAX_ERROR',
    PARTIAL_FAILED = 'PARTIAL_FAILED',
    CONCURRENCY_FAILED = 'CONCURRENCY_FAILED',
    INVALID_CARD_INFO = 'INVALID_CARD_INFO',
    INVALID_TRANSACTION = 'INVALID_TRANSACTION',
    WITHDRAWAL_TRANSACTION_EXISTS = 'WITHDRAWAL_TRANSACTION_EXISTS',
    BALANCE_UPDATE_FAILED = 'BALANCE_UPDATE_FAILED',
    TOURNAMENT_STARTED = 'TOURNAMENT_STARTED',
    EXCEEDED_THE_MAXIMUM = 'EXCEEDED_THE_MAXIMUM',
    EXCEEDED_THE_LIMIT = 'EXCEEDED_THE_LIMIT',
    INVALID_CURRENCY = 'INVALID_CURRENCY',
    REMOTE_SERVER_ERROR = 'REMOTE_SERVER_ERROR',
    INVALID_PARAMETER = 'INVALID_PARAMETER',
    INVALID_DESTINATION_TAG = 'INVALID_DESTINATION_TAG',
    INVALID_PAYMENT_CONFIGURATION = 'INVALID_PAYMENT_CONFIGURATION',
    INVALID_PAYMENT_METHOD = 'INVALID_PAYMENT_METHOD',
    INVALID_DATA_FORMAT = 'INVALID_DATA_FORMAT',
    SOLD_OUT_STAKE = 'SOLD_OUT_STAKE',
    INVALID_TOKEN = 'INVALID_TOKEN',
    INVALID_RECAPTCHA_TOKEN = 'INVALID_RECAPTCHA_TOKEN',
    ALREADY_VERIFIED = 'ALREADY_VERIFIED',
    EXPIRED_TOKEN = 'EXPIRED_TOKEN',
    NOT_ENOUGH_RESEND_TERMS = 'NOT_ENOUGH_RESEND_TERMS',
    INVALID_MEMBER = 'INVALID_MEMBER',
    PAYMENT_REFUND_FAILED = 'PAYMENT_REFUND_FAILED',
    GP_ACCOUNT_NOT_FOUND = 'GP_ACCOUNT_NOT_FOUND',
    INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
    EXPIRED_VERIFICATION_CODE = 'EXPIRED_VERIFICATION_CODE',
    GGPASS_ERROR = 'GGPASS_ERROR',
    GGPASS_UNAUTHORIZED = 'GGPASS_UNAUTHORIZED',
    GGPASS_REFRESH_TOKEN_EMPTY = 'GGPASS_REFRESH_TOKEN_EMPTY',
    DUPLICATE_ONEPASSID = 'DUPLICATE_ONEPASSID'
}

export interface ErrorResponse {
    errorCode: string;
    description: string;
    errorParameters: string[];
    exception: string;
}

// GGPassError
export enum GGPassErrorCodes {
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    INVALID_REQUEST = 'INVALID_REQUEST',
    DOMAIN_NOT_ALLOWED = 'DOMAIN_NOT_ALLOWED',
    USERNAME_ALREADY_EXISTS = 'USERNAME_ALREADY_EXISTS',
    INVALID_USERNAME = 'INVALID_USERNAME',
    INVALID_COUNTRY_CODE = 'INVALID_COUNTRY_CODE',
    INVALID_PASSWORD_LENGTH = 'INVALID_PASSWORD_LENGTH',
    INVALID_PASSWORD_PATTERN = 'INVALID_PASSWORD_PATTERN',
    PASSWORD_NOT_ALLOWED_INCLUDE_USERNAME = 'PASSWORD_NOT_ALLOWED_INCLUDE_USERNAME',
    REQUIRED_TNC_AGREEMENT = 'REQUIRED_TNC_AGREEMENT',
    INVALID_SIGNUP_TOKEN = 'INVALID_SIGNUP_TOKEN',
    RESEND_TERM_LIMITED = 'RESEND_TERM_LIMITED',
    RESEND_LIMITED = 'RESEND_LIMITED',
    SENDGRID_ERROR = 'SENDGRID_ERROR',
    REQUIRED_VERIFICATION_CODE = 'REQUIRED_VERIFICATION_CODE',
    INVALID_VERIFICATION_CODE = 'INVALID_VERIFICATION_CODE',
    UNMATCHED_VERIFICATION_CODE = 'UNMATCHED_VERIFICATION_CODE',
    USED_VERIFICATION_CODE = 'USED_VERIFICATION_CODE',
    EXPIRED_VERIFICATION_CODE = 'EXPIRED_VERIFICATION_CODE',
    PASSWORD_ATTEMPTS_EXCEEDED = 'PASSWORD_ATTEMPTS_EXCEEDED',
    ONEPASS_NOT_FOUND = 'ONEPASS_NOT_FOUND',
    ONEPASS_SIGNUP_NOT_FOUND = 'ONEPASS_SIGNUP_NOT_FOUND',
    BRAND_SITE_NOT_FOUND = 'BRAND_SITE_NOT_FOUND',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    REQUIRED_MFA_CODE = 'REQUIRED_MFA_CODE',
    INVALID_TOTP_CODE = 'INVALID_TOTP_CODE',
    ONEPASS_SUSPENDED = 'ONEPASS_SUSPENDED',
    ONEPASS_BLOCKED = 'ONEPASS_BLOCKED',
    ONEPASS_CLOSED = 'ONEPASS_CLOSED',
    REQUIRED_SOCIAL_LOGIN = 'REQUIRED_SOCIAL_LOGIN',
    ONEPASS_TIME_LOCK = 'ONEPASS_TIME_LOCK',
    ONEPASS_RESET_PASSWORD_LOCK = 'ONEPASS_RESET_PASSWORD_LOCK',
    ONEPASS_TIME_OR_RESET_PASSWORD_LOCK = 'ONEPASS_TIME_OR_RESET_PASSWORD_LOCK',
    REFRESH_TOKEN_NOT_FOUND = 'REFRESH_TOKEN_NOT_FOUND',
    REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
    AVATAR_PROHIBITED = 'AVATAR_PROHIBITED',
    ONEPASS_ACCOUNT_NOT_FOUND = 'ONEPASS_ACCOUNT_NOT_FOUND',
    ONEPASS_ACTIVE_ACCOUNT_ALREADY_EXISTS = 'ONEPASS_ACTIVE_ACCOUNT_ALREADY_EXISTS',
    ONEPASS_ACCOUNT_ALREADY_EXISTS = 'ONEPASS_ACCOUNT_ALREADY_EXISTS',
    BRAND_NOT_FOUND = 'BRAND_NOT_FOUND',
    INVALID_SEC_CODE = 'INVALID_SEC_CODE',
    INVALID_RECAPTCHA = 'INVALID_RECAPTCHA',
    RECAPTCHA_TIMEOUT = 'RECAPTCHA_TIMEOUT',
    RECAPTCHA_INTERNAL_ERROR = 'RECAPTCHA_INTERNAL_ERROR',
    BRAND_SITE_TEMPLATE_CONFIG_NOT_FOUND = 'BRAND_SITE_TEMPLATE_CONFIG_NOT_FOUND',
    ONEPASS_ACCOUNT_BRAND_SITE_ALREADY_EXISTS = 'ONEPASS_ACCOUNT_BRAND_SITE_ALREADY_EXISTS',
    ACCESS_DENIED = 'ACCESS_DENIED',
    NICKNAME_PROHIBITED = 'NICKNAME_PROHIBITED',
    AVATAR_NOT_FOUND = 'AVATAR_NOT_FOUND',
    AVATAR_SUSPENDED = 'AVATAR_SUSPENDED',
    NICKNAME_ALREADY_EXISTS = 'NICKNAME_ALREADY_EXISTS',
    NICKNAME_ALREADY_SET = 'NICKNAME_ALREADY_SET',
    USERNAME_NOT_FOUND = 'USERNAME_NOT_FOUND',
    ONEPASS_ACCOUNT_CITIZENSHIP_NOT_ALLOWED = 'ONEPASS_ACCOUNT_CITIZENSHIP_NOT_ALLOWED',
    INVALID_CURRENT_PASSWORD = 'INVALID_CURRENT_PASSWORD',
    PASSWORD_SAME_AS_OLD = 'PASSWORD_SAME_AS_OLD',
    BRAND_SITE_SECURITY_CONFIG_NOT_FOUND = 'BRAND_SITE_SECURITY_CONFIG_NOT_FOUND',
    INVALID_USER = 'INVALID_USER',
    VERIFICATION_CODE_ATTEMPTS_EXCEEDED = 'VERIFICATION_CODE_ATTEMPTS_EXCEEDED',
    GGPASS_EMAIL_EMPTY = 'GGPASS_EMAIL_EMPTY',
    TELESIGN_INVALID_PHONE_NUMBER = 'TELESIGN_INVALID_PHONE_NUMBER'
}

export enum StakingNowType {
    TopSold = 1,
    FeaturedPlayer = 2,
    RisingStar = 3
}

export enum SponsorshipRankingType {
    AllTime = 1,
    Yearly = 2
}

export enum AuthenticationMethod {
    DontUse = 0,
    Email = 1,
    GoogleOtp = 2,
    WithdrawalPassword = 3,
    Biometric = 4,
    Mobile = 5
}

export enum MFAMethod {
    Email = 'EMAIL',
    Google = 'GOOGLE',
    Mobile = 'MOBILE'
}

export enum SecurityLevel {
    Off = 'OFF',
    Low = 'LOW',
    Medium = 'MEDIUM',
    High = 'HIGH'
}

export enum WebAuthnAttType {
    'none',
    'indirect',
    'direct'
}

export enum WebAuthnAuthType {
    'platform',
    'cross-platform'
}

export enum WebAuthnUserVerification {
    'required',
    'preferred'
}

export enum PubKeyCredParamsType {
    'public-key'
}

export enum CredentialType {
    WithdrawalPassword,
    Biometric
}

export enum CountryCode {
    'AD' = 376,
    'AE' = 971,
    'AF' = 93,
    'AG' = 1,
    'AI' = 1,
    'AL' = 355,
    'AM' = 374,
    'AN' = 599,
    'AO' = 244,
    'AQ' = 672,
    'AR' = 54,
    'AS' = 1,
    'AT' = 43,
    'AU' = 61,
    'AW' = 297,
    'AX' = 672,
    'AZ' = 994,
    'BA' = 387,
    'BB' = 1,
    'BD' = 880,
    'BE' = 32,
    'BF' = 226,
    'BG' = 359,
    'BH' = 973,
    'BI' = 257,
    'BJ' = 229,
    'BL' = 590,
    'BM' = 1,
    'BN' = 673,
    'BO' = 591,
    'BQ' = 599,
    'BR' = 55,
    'BS' = 1,
    'BT' = 975,
    'BV' = 47,
    'BW' = 267,
    'BY' = 375,
    'BZ' = 501,
    'CA' = 1,
    'CC' = 61,
    'CD' = 243,
    'CF' = 236,
    'CG' = 242,
    'CH' = 41,
    'CI' = 225,
    'CK' = 682,
    'CL' = 56,
    'CM' = 237,
    'CN' = 86,
    'CO' = 57,
    'CR' = 506,
    'CU' = 53,
    'CV' = 238,
    'CW' = 599,
    'CX' = 61,
    'CY' = 357,
    'CZ' = 420,
    'DE' = 49,
    'DJ' = 253,
    'DK' = 45,
    'DM' = 1,
    'DO' = 1,
    'DZ' = 213,
    'EC' = 593,
    'EE' = 372,
    'EG' = 20,
    'EH' = 212,
    'ER' = 291,
    'ES' = 34,
    'ET' = 251,
    'FI' = 358,
    'FJ' = 679,
    'FK' = 500,
    'FM' = 691,
    'FO' = 298,
    'FR' = 33,
    'GA' = 241,
    'GB' = 44,
    'GD' = 1,
    'GE' = 995,
    'GF' = 594,
    'GG' = 44,
    'GH' = 233,
    'GI' = 350,
    'GL' = 299,
    'GM' = 220,
    'GN' = 224,
    'GP' = 590,
    'GQ' = 240,
    'GR' = 30,
    'GS' = 500,
    'GT' = 502,
    'GU' = 1,
    'GW' = 245,
    'GY' = 592,
    'HK' = 852,
    'HM' = 672,
    'HN' = 504,
    'HR' = 385,
    'HT' = 509,
    'HU' = 36,
    'ID' = 62,
    'IE' = 353,
    'IL' = 972,
    'IM' = 44,
    'IN' = 91,
    'IO' = 246,
    'IQ' = 964,
    'IR' = 98,
    'IS' = 354,
    'IT' = 39,
    'JE' = 44,
    'JM' = 1,
    'JO' = 962,
    'JP' = 81,
    'KE' = 254,
    'KG' = 996,
    'KH' = 855,
    'KI' = 686,
    'KM' = 269,
    'KN' = 1,
    'KP' = 850,
    'KR' = 82,
    'KW' = 965,
    'KY' = 1,
    'KZ' = 7,
    'LA' = 856,
    'LB' = 961,
    'LC' = 1,
    'LI' = 423,
    'LK' = 94,
    'LR' = 231,
    'LS' = 266,
    'LT' = 370,
    'LU' = 352,
    'LV' = 371,
    'LY' = 218,
    'MA' = 212,
    'MC' = 377,
    'MD' = 373,
    'ME' = 382,
    'MF' = 590,
    'MG' = 261,
    'MH' = 692,
    'MK' = 389,
    'ML' = 223,
    'MM' = 95,
    'MN' = 976,
    'MO' = 853,
    'MP' = 1,
    'MQ' = 596,
    'MR' = 222,
    'MS' = 1,
    'MT' = 356,
    'MU' = 230,
    'MV' = 960,
    'MW' = 265,
    'MX' = 52,
    'MY' = 60,
    'MZ' = 258,
    'NA' = 264,
    'NC' = 687,
    'NE' = 227,
    'NF' = 672,
    'NG' = 234,
    'NI' = 505,
    'NL' = 31,
    'NO' = 47,
    'NP' = 977,
    'NR' = 674,
    'NU' = 683,
    'NZ' = 64,
    'OM' = 968,
    'PA' = 507,
    'PE' = 51,
    'PF' = 689,
    'PG' = 675,
    'PH' = 63,
    'PK' = 92,
    'PL' = 48,
    'PM' = 508,
    'PN' = 64,
    'PR' = 1,
    'PS' = 970,
    'PT' = 351,
    'PW' = 680,
    'PY' = 595,
    'QA' = 974,
    'RE' = 262,
    'RO' = 40,
    'RS' = 381,
    'RU' = 7,
    'RW' = 250,
    'SA' = 966,
    'SB' = 677,
    'SC' = 248,
    'SD' = 249,
    'SE' = 46,
    'SG' = 65,
    'SH' = 290,
    'SI' = 386,
    'SJ' = 47,
    'SK' = 421,
    'SL' = 232,
    'SM' = 378,
    'SN' = 221,
    'SO' = 252,
    'SR' = 597,
    'SS' = 211,
    'ST' = 239,
    'SV' = 503,
    'SX' = 1,
    'SY' = 963,
    'SZ' = 268,
    'TC' = 1,
    'TD' = 235,
    'TF' = 262,
    'TG' = 228,
    'TH' = 66,
    'TJ' = 992,
    'TK' = 690,
    'TL' = 670,
    'TM' = 993,
    'TN' = 216,
    'TO' = 676,
    'TR' = 90,
    'TT' = 1,
    'TV' = 688,
    'TW' = 886,
    'TZ' = 255,
    'UA' = 380,
    'UG' = 256,
    'UM' = 1,
    'US' = 1,
    'UY' = 598,
    'UZ' = 998,
    'VA' = 379,
    'VC' = 1,
    'VE' = 58,
    'VG' = 1,
    'VI' = 1,
    'VN' = 84,
    'VU' = 678,
    'WF' = 681,
    'WS' = 685,
    'YE' = 967,
    'YT' = 262,
    'ZA' = 27,
    'ZM' = 260,
    'ZW' = 263
}
