export const environment = {
    production: false,
    env: 'STAGE',
    r2Host: 'https://bw-stage.wsopplus.net/Pokerstake',
    backendHost: 'https://api-stage.pokerstake.com',
    paymentHost: 'https://pay-stage.pokerstake.com',
    stream: 'https://stream7-stage.pokerstake.com',
    imageHost: '//cdn.pokerstake.com',
    pocketFives: 'https://contents-stage.pokerstake.com',
    p7Host: '//staking7-stage.pokerstake.com',
    recaptchaSiteKey: '6Ldru40jAAAAAHamQbkYA2RYxp3UaKcxzJ0L5YYI',
    recaptchaEnterpriseSiteKey: '6LevNJknAAAAADW9RLPTOWdAz8PhXeI0kLAXUH09',
    nuveiMerchantId: '3261468619376904786',
    nuveiMerchantSiteId: '234588',
    ggPokerNewsHost: 'https://news.ggp-stage.com',
    appVersion: require('../../package.json').version,
    ssoGoogleUrl: 'https://sso-google-stage.ggpass.com',
    ssoAppleUrl: 'https://sso-apple-stage.ggpass.com',
    ssoFacebookUrl: 'https://sso-facebook-stage.ggpass.com'
};
