import { DTO } from '@models';
import { CredentialType, MFAMethod } from '@models/dto/enums/common';
import { FormControl } from '@angular/forms';
import { Enums } from './aggregate';

export { Enums };

export interface DropDownListItem<T = any> {
    label: string;
    value: T;
    className?: string;
}

export enum NPayCoinList {
    Bitcoin = 'BTC',
    USDC = 'USDC',
    Tether = 'USDT',
    Ethereum = 'ETH'
}

export interface PageOptions {
    currentPage: number;
    pageSize: number;
    totalCount?: number;
}

export enum SoldPercentType {
    Low = 'Low',
    Middle = 'Middle',
    High = 'High'
}

export enum StakingCardItemType {
    Default = 'Default',
    MyStaking = 'MyStaking',
    MyOffering = 'MyOffering',
    Series = 'Series',
    FeaturedPlayer = 'FeaturedPlayer',
    Tournament = 'Tournament',
    RisingStar = 'RisingStar',
    StakeAgain = 'StakeAgain',
    StakingNow = 'StakingNow',
    MainSeries = 'MainSeries',
    FollowingPlayers = 'FollowingPlayers',
    SellerProfile = 'SellerProfile'
}

export interface MyStakingSoldListItem extends DTO.FrontOffice.Stake.MyStakeInfo {
    isSoldOut: boolean;
    isAfterStartTime: boolean;
}

export interface MyStakingBoughtListItem extends DTO.FrontOffice.Stake.PurchasedStakeInfo {
    isSoldOut: boolean;
    isAfterStartTime: boolean;
}

export interface SpecialSellerItem extends DTO.FrontOffice.Seller.SellerInfo {
    isActive: boolean;
}

export interface StakeReplyItem extends DTO.FrontOffice.StakeReply.StakeReplyInfo {
    isEditReply: boolean;
    isDeleteReply: boolean;
}

export interface StakingListItem extends DTO.FrontOffice.Stake.StakeInfo {
    adjsMarkup: number;
    isSoldUnderThreshold: boolean;
    isOpenedSellerArticle: boolean;
    isEnterBuyShares: boolean;
    buyInType: Enums.Common.BuyInType;
    buySharesFormControl: FormControl;
    isVisibleConfirmBuyAction: boolean;
    minSharesPercent: number;
    isLoadingBuyShares: boolean;
    isBoughtStaking: boolean;
    isArticleLoader: boolean;
    sellerMessageFormControl: FormControl;
    sellerReplyList: DTO.FrontOffice.StakeReply.StakeReplyInfo[];
    isSoldOut: boolean;
    priceToBuy: number;
    isInsufficientBalanceMessage: boolean;
    isAfterStartTime: boolean;
    passwordFormControl: FormControl;
    isErrorStakingPassword: boolean;
    isNotUSDCurrency: boolean;
    ggSellerImageUrl: string;
    isInvalid: boolean;
    soldPercentType: SoldPercentType;
}

export interface StakingCardListItem extends DTO.FrontOffice.Stake.StakeInfo {
    adjsMarkup: number;
    isBoughtStaking: boolean;
    isSoldOut: boolean;
    isAfterStartTime: boolean;
    isNotUSDCurrency: boolean;
    ggSellerImageUrl: string;
    isInvalid: boolean;
    soldPercentType: SoldPercentType;
    isPreview: boolean;
    isOpenDetail: boolean;
    stakingAddress: string;
    isRealTimeUpdate: boolean;
}

export interface StakingTournamentInfoList extends DTO.FrontOffice.Stake.TournamentStakeInfo {
    tournamentStakeList: StakingCardListItem[];
}

export interface StakingSeriesInfoList extends DTO.FrontOffice.Stake.SeriesStakeInfo {
    stakeList: StakingCardListItem[];
}

export interface StakingCardDetailItem extends DTO.FrontOffice.Stake.StakeDetailInfo {
    adjsMarkup: number;
    isSoldUnderThreshold: boolean;
    buyInType: Enums.Common.BuyInType;
    buySharesFormControl: FormControl;
    isVisibleConfirmBuyAction: boolean;
    minSharesPercent: number;
    isLoadingBuyShares: boolean;
    isBoughtStaking: boolean;
    isArticleLoader: boolean;
    playerMessageFormControl: FormControl;
    playerMessageEditFormControl: FormControl;
    stakeReplyList: StakeReplyItem[];
    isSoldOut: boolean;
    priceToBuy: number;
    isInsufficientBalanceMessage: boolean;
    isAfterStartTime: boolean;
    passwordFormControl: FormControl;
    isErrorStakingPassword: boolean;
    isNotUSDCurrency: boolean;
    ggSellerImageUrl: string;
    isInvalid: boolean;
    soldPercentType: SoldPercentType;
    isPreview: boolean;
    isOpenDetail: boolean;
    stakingAddress: string;
    convertNote: string;
    isPassword: boolean;
}

export interface PaymentConfigListItem extends DTO.FrontOffice.Balance.PaymentGatewayConfig {
    displayImageUrl: string;
}

export enum DisplayTransactionType {
    Deposit = 'Deposit',
    Withdrawal = 'Withdrawal',
    WithdrawalCanceled = 'Withdrawal Canceled',
    WithdrawalFailed = 'Withdrawal Failed',
    WithdrawalDecline = 'Withdrawal Decline',
    StakePurchase = 'Purchased',
    StakeResult = 'Performance Results',
    StakeRefund = 'Staking Refund',
    AdminCredit = 'Admin Credit',
    AdminDebit = 'Admin Debit',
    PrizeSettlement = 'Prize Settlement',
    StakeSale = 'Took Staking',
    PromotionCredit = 'Promotion Credit',
    StakeBounty = 'Bounty',
    PaymentRefund = 'Refund',
    PaymentRefundFailed = 'Refund Failed',
    PurchaseCanceled = 'Share Canceled',
    StakeBountyJackpot = 'GG Bounty Jackpot'
}

export interface TransactionHistoryItem extends DTO.FrontOffice.Balance.TransactionHistoryInfo {
    displayTransactionType: DisplayTransactionType;
    displayDetails: string;
}

export enum DepositStatus {
    None,
    Pending,
    Succeeded,
    Failed
}

export enum PostMessageType {
    StripeAccountStatus
}

export interface PostMessage {
    type: PostMessageType;
    data: any;
}

export enum StakingUrlType {
    StakingUrl,
    SellerUrl
}

export interface BraintreeDepositResult {
    isSuccess: boolean;
    transactionId: number;
    amount: number;
}

export enum DisplayStakeTransactionStatus {
    Win = 'Win',
    Lose = 'Lose',
    Refund = 'Refund',
    Unsettled = 'Unsettled',
    Canceled = 'Share Canceled'
}

export interface MyStakingInfoItem extends DTO.FrontOffice.Stake.GetMyStakeInfoResponse {
    minimumPercent: number;
    maximumPercent: number;
}

export interface MyStakeTransactionItem extends DTO.FrontOffice.Stake.StakeTransactionInfo {
    displayStatus: DisplayStakeTransactionStatus;
    currency: DTO.Enums.Common.TournamentCurrency;
}

export enum StakerSortType {
    Staker,
    Purchase,
    Share,
    Time
}

export interface P5Menu {
    id: string;
    display: string;
    path: string;
    active: boolean;
    isSubmenu?: boolean;
    subMenu?: {
        id: string;
        display: string;
        path: string;
        active: boolean;
    }[];
}

export enum P5HasSubMenu {
    Account = 'Account',
    Rankings = 'Rankings'
}

export enum P5Has2DepthSubMenu {
    Badges = 'Badges',
    MIOnlinePoker = 'MI Online Poker',
    PAOnlinePoker = 'PA Online Poker',
    NJOnlinePoker = 'NJ Online Poker'
}

export enum StakingFilterType {
    Player = 'Player',
    Venue = 'Venue',
    Tournaments = 'Tournaments',
    BuyIn = 'Buy-in',
    Markup = 'Markup',
    StartTime = 'Start Time',
    Available = 'Available'
}

export interface StakingFilterItems {
    type?: StakingFilterType;
    label?: string;
    isActive: boolean;
    isApply: boolean;
}

export enum BuyInFilterType {
    All = 'All',
    Micro = 'Micro',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High'
}

export enum MarkupFilterType {
    All = 'All',
    Reverse = 'Reverse',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High'
}

export enum AvailableFilterType {
    All = 'All',
    Micro = '0% ~ 9.99%',
    Low = '10% ~ 19.99%',
    Medium = '20% ~ 29.99%',
    High = '30% ~ 39.99%',
    SuperHigh = '40% ~ 50%'
}

export enum QuickDateTypes {
    Today = 'Today',
    Tomorrow = 'Tomorrow',
    ThisWeek = 'This Week',
    NextWeek = 'Next Week',
    ThisMonth = 'This Month',
    NextMonth = 'Next Month',
    CustomRange = 'Custom Range'
}

export interface CalendarDateOptionItem extends DropDownListItem {
    isActive: boolean;
}

export interface SellerItem extends DropDownListItem {
    type: DTO.Enums.Common.OriginType;
}

export interface SliderItem {
    value: Array<number>;
    shortcutType: BuyInFilterType | MarkupFilterType | AvailableFilterType;
}

export interface StakingFilterState {
    seller: Array<SellerItem>;
    venue: Array<DropDownListItem>;
    tournament: string;
    buyIn: SliderItem;
    markup: SliderItem;
    startTime: Array<Date>;
    quickTimeType: QuickDateTypes;
    available: SliderItem;
    showComplete: boolean;
}

export interface MyStakingFormDataState {
    seller: number;
    markup: string;
    sharesToSell: number;
    useThreshold: boolean;
    threshold?: number;
    usePurchaseCap: boolean;
    purchaseCap?: number;
    note: string;
    realNote: string;
    useSaveNote: boolean;
}

export interface StakingSliderOptions {
    filterType: StakingFilterType;
    shortcutType: any;
    min: number;
    max: number;
    step: number;
}

export enum MystakingTabStates {
    All = 'All',
    Upcoming = 'Upcoming',
    Running = 'Running',
    Finished = 'Finished'
}

export enum StakingSearchOption {
    StartTime = 'Start Time',
    Latest = 'Latest',
    MarkupLow = 'Markup Low',
    MarkupHigh = 'Markup High',
    BuyInLow = 'Buy-in Low',
    BuyInHigh = 'Buy-in High'
}

export interface StakeViewMoreList {
    [key: string]: DTO.FrontOffice.Stake.StakeInfo[];
}

export interface StakeViewMoreListToArray {
    name: string;
    list: DTO.FrontOffice.Stake.StakeInfo[];
}

export enum StakeViewMoreFilterOptions {
    SellerName = 'Seller',
    StartTime = 'Tournament Start Time',
    CreatedAt = 'Latest',
    MarkupLow = 'Markup Low',
    MarkupHigh = 'Markup High',
    BuyInLow = 'Buy-in Low',
    BuyInHigh = 'Buy-in High',
    AvailableLow = 'Most Sold'
}

export enum MyStakingBuyFilterType {
    All = 'All',
    Micro = 'Less than $100',
    Low = '$100 ~ $999.99',
    Medium = '$1,000 ~ 4,999.99',
    High = '$5,000 ~ $9,999.99',
    SuperHigh = 'More than $10,000'
}

export enum MyStakingSliderTypes {
    ShareToSell = 'Share To Sell',
    PurchaseCap = 'Purchase Cap',
    Threshold = 'Threshold',
    Markup = 'Markup'
}

export interface MyStakingFilterItem extends DropDownListItem {
    isChecked: boolean;
}

export enum DateSearchOptions {
    All = 'All',
    Today = 'Today',
    Week1 = '1 Week',
    Month1 = '1 Month',
    Month3 = '3 Month',
    Month6 = '6 Month'
}

export interface GGMaintenanceConfig {
    isMaintenance: boolean;
    startDate: string;
    endDate: string;
}

export interface WSOPCAMaintenanceConfig extends GGMaintenanceConfig {}

export interface P5MaintenanceConfig extends GGMaintenanceConfig {
    title: string;
    description: string;
}

export interface MaintenanceConfigInfo {
    isInDevIp?: boolean;
    gg: GGMaintenanceConfig;
    p5: P5MaintenanceConfig;
    wsopca: WSOPCAMaintenanceConfig;
}

export interface MaintenanceConfigResponse extends MaintenanceConfigInfo {}

export enum UpdateType {
    Info = 0,
    Status = 1
}

export enum StakeProgress {
    Processing = 0,
    Canceled = 1
}

export interface StakeUpdateResponseMessage {
    updateType: UpdateType;
    stakeId: number;
    referenceId: number;
    soldPercent: number;
    offeredPercent: number;
    adjustStackMarkup: number;
    stakeProgress: StakeProgress;
}

export interface PaymentProcessStatusResponseMessage {
    processStatus: DTO.Enums.Common.PaymentProcessStatus;
    transactionId: number;
}

export interface UpdateUserBalance {
    value: number;
    isUpdate: boolean;
}

export interface PaymentUpdateResponseMessage {
    paymentType: DTO.Enums.Common.PaymentType;
    paymentStatus: DTO.Enums.Common.PaymentProcessStatus;
    transactionId: number;
    pspName: DTO.Enums.Common.PSPName;
    pspDisplayName: string;
    amount: number;
    description: string;
}

export interface InlineStyles {
    top: string;
    left: string;
}

export enum CardBrandEnum {
    Visa = 'Visa',
    MasterCard = 'Master Card',
    AmericanExpress = 'American Express',
    Discover = 'Discover',
    DinersClub = 'Diners Club',
    JCB = 'JCB',
    Maestro = 'Maestro',
    UnionPay = 'Union Pay',
    Dankort = 'Dankort',
    Forbrugsforeningen = 'Forbrugsforeningen'
}

export interface CardValidation {
    type: CardBrandEnum;
    patterns: number[];
    mask: any;
    format: RegExp;
    length: number[];
    cvvLength: number[];
    luhn: boolean;
    imgName: string;
}

export interface CustomerPaymentProfileItems extends DTO.PaymentGateway.AuthorizeNet.PaymentProfile {
    isSelected: boolean;
    cardImageName: string;
    cardBrandName: string;
}

export interface NuveiCardProfileItems {
    isSelected: boolean;
    userPaymentOptionId: string;
    clientRequestId: string;
    cardImageName: string;
    cardBrandName: string;
    cardNumber: string;
}

export enum CancelSellActionReasons {
    Reason1 = 'Didn’t fire this bullet',
    Reason2 = 'Didn’t sell enough action',
    Reason3 = 'Decided to not play the event',
    ScheduleIssue = 'Schedule Issue',
    Others = 'Others'
}

export enum StakingNowFilterType {
    FeaturedPlayer = 'Featured Player',
    TopSold = 'Top Sold',
    RisingStar = 'Rising Star'
}

export enum SponsorshipRankingFilterType {
    AllTime = 'All Time',
    Yearly = '2024'
}

export interface YoastHeadInfo {
    article_modified_time: string;
    article_published_time: string;
    og_description: string;
    og_image: string;
    og_locale: string;
    og_site_name: string;
    og_title: string;
    og_type: string;
    og_url: string;
    robots: any;
    schema: any;
    title: string;
    twitter_card: string;
}

export interface WpTagInfo {
    id: number;
    slug: string;
    name: string;
}

export interface WpAuthorInfo {
    id: number;
    username: string;
    display_name: string;
    avatar_url: string;
    count_posts: number;
}

export interface WpPosts {
    author: number;
    author_raw: WpAuthorInfo;
    categories: number[];
    class_list: string[];
    comment_status: string;
    content: { rendered: string; protected: boolean };
    date: string;
    date_gmt: string;
    excerpt: { rendered: string; protected: boolean };
    featured_media: number;
    featured_media_raw: any;
    format: string;
    guid: { rendered: string };
    id: number;
    link: string;
    meta: { footnotes: string };
    modified: string;
    modified_gmt: string;
    ping_status: string;
    slug: string;
    status: string;
    sticky: boolean;
    tags: string[];
    template: string;
    title: { rendered: any };
    type: string;
    yoast_head: string;
    yoast_head_json: YoastHeadInfo;
}

export enum WpTags {
    News = 8,
    Interviews = 11,
    Legislation = 13,
    Nevada = 40,
    NewJersey = 41,
    TournamentReview = 248,
    WSOP2022 = 9856,
    ChangedMyLife = 9877,
    Series3 = 9890
}

export interface WpPostsRequest {
    id: number | null;
    page: number | null;
    per_page: number | null;
    tags: number | null;
    author: number | null;
    slug: string | null;
    _fields: string[] | null;
    psk_only: number | null;
}

export enum WpTagsName {
    All = 'All',
    News = 'News',
    Interviews = 'Interviews',
    Legislation = 'Legislation',
    Nevada = 'Nevada',
    NewJersey = 'NewJersey'
}

export interface WpCommentRequest {
    post: number | null;
    page: number | null;
    per_page: number | null;
    order: 'asc' | 'desc' | null;
}

export interface WpCategoryRequest {
    post: number | null;
    page: number | null;
    per_page: number | null;
}

export interface WpTagsRequest {
    post?: number | null;
    page?: number | null;
    per_page?: number | null;
    slug?: string | null;
    search?: string | null;
    _fields?: string[] | null;
}

export interface WpAuthorsRequest {
    slug?: string | null;
    _fields?: string[] | null;
}

export interface WpPostsMeta {
    og_description: string;
    og_image: any;
    og_locale: string;
    og_site_name: string;
    og_title: string;
    og_type: string;
    og_url: string;
    title: string;
    twitter_card: string;
}

// Helper > Date > getIntervalExpireDday
export interface ExpireDDayResponse {
    isExpired: boolean;
    dDay: string;
}

export interface MyCountryInfo {
    countryIso: string;
    countryName: string;
    countryImageUrl: string;
}

export interface P5YoutubeListItem {
    videoId: string;
    videoUrl: string;
    thumbnailImage: string;
    title: string;
}

export interface TwoFactorReturnValue {
    verificationCode: string;
    verificationId: number;
    authenticationMethod?: DTO.Enums.Common.AuthenticationMethod | null;
}

export enum SecurityLevelDescriptions {
    Off = '(Not recommended) 2FA authentication is not required on any login attempt.',
    Low = 'Notification will be sent upon suspicious login activity.',
    Medium = '2FA authentication is required only upon suspicious login activity.',
    High = '2FA authentication is required on every login attempt.'
}

export interface SecurityLevelItem {
    securityLevel: DTO.Enums.Common.SecurityLevel | null;
    level: string;
    label: string;
    isDisabled: boolean;
}

export interface AuthenticationMethodItem extends DropDownListItem<MFAMethod> {
    isDisabled: boolean;
}

export enum NuveiStatus {
    Error = 'ERROR',
    Success = 'SUCCESS'
}

export enum WebAuthnCredentialStatus {
    Success,
    Fail
}

export enum WebAuthnReturnType {
    WithdrawalPassword,
    Biometric,
    Cancel,
    Fail
}

export enum AuthenticationType {
    Withdrawal,
    Delete
}

export enum DeleteTargetAuthenticator {
    WithdrawalPassword = CredentialType.WithdrawalPassword,
    Biometric = CredentialType.Biometric
}

export enum CreateWithdrawalPasswordStatus {
    Success,
    Fail,
    Cancel
}

export interface SecondaryAuthReturnValue {
    credentialMethod: WebAuthnReturnType;
    data: string | DTO.FrontOffice.WebAuthn.PostMakeAssertionRequest | null;
}

export enum WithdrawalAuthenticatorType {
    TwoFactor,
    WithdrawalPassword
}

export interface WithdrawalToggleButton {
    withdrawalPassword: boolean;
    biometric: boolean;
}

export enum MarketPlaceStakingType {
    MyStaking = 'My Staking',
    MyOffering = 'My Offering',
    StakeAgain = 'Stake Again',
    FollowingPlayer = 'Following Player',
    FirstSeries = 'First Series',
    FeaturedPlayer = 'Featured Player',
    FeaturedPlayerStaking = 'Featured Player Staking',
    Series = 'Series',
    RisingStar = 'Rising Star',
    GGTournament = 'GGTournament'
}

export enum StakingShortCutFilterType {
    LastChance = 'Last Chance',
    Next24hrs = 'Next 24hrs',
    SellingHot = 'Selling Hot',
    RunningHot = 'Running Hot',
    GGMillions = 'GG Millions',
    NoMarkup = 'No Markup',
    HighestRoller = 'High Roller',
    HighestGuarantee = 'Highest Guarantee'
}

export enum CurrencyCorrectionValueList {
    USD = 1,
    EUR = 1,
    GBP = 1,
    RUB = 100,
    JPY = 100,
    KRW = 1000,
    CNY = 10,
    HKD = 10,
    TWD = 10,
    KHR = 1000,
    VND = 10000,
    PHP = 10,
    INR = 100,
    THB = 10,
    IDR = 10000,
    MYR = 1,
    SGD = 1,
    SEK = 10,
    NOK = 10,
    CHF = 1,
    CAD = 1,
    AMD = 100,
    DKK = 10,
    AUD = 1,
    BRL = 1
}

export enum BannerTarget {
    _blink = 0,
    _self = 1,
    _parent = 2,
    _top = 3
}

export interface BannerInfo {
    imageUrl: string;
    imageUrlMobile?: string;
    linkUrl?: string;
    bannerTarget: BannerTarget;
    startDate: string;
    endDate: string;
    isDisplay: boolean;
}

export interface GetBannerListResponse {
    mainBanner?: BannerInfo[];
    subBanner?: BannerInfo[];
    storiesMainBanner?: BannerInfo[];
    storiesSubBanner?: BannerInfo[];
}

export interface ResetFilterOptions {
    isResetShowCompleted: boolean;
    isRouteStakingPage: boolean;
}

export enum BannerType {
    MainBanner,
    SubBanner,
    StoriesMain,
    StoriesSub
}
